@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_extrabold.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_regular.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/proxima_nova_light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: "proxima_nova_regular", sans-serif;
  background-color: "#FBFBFF";
  padding: 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ol {
  counter-reset: section;
}
ol > li {
  counter-increment: section;
}
ol > li::before {
  content: counters(section, ".") " ";
}
ol ol > li {
  counter-reset: subsection;
}
ol ol > li::before {
  counter-increment: subsection;
  content: counters(section, ".") "." counter(subsection) " ";
}
