.slick-dots {
  bottom: 10px; /* Position of the dots */
}

.slick-dots li {
  margin: 0 5px; /* Space between dots */
}

.slick-dots li button {
  background-color: rgba(217, 217, 217, 1); /* Default dot color */
  border: none; /* Remove border */
  border-radius: 50%; /* Make the dots circular */
  width: 12px; /* Default dot width */
  height: 12px; /* Default dot height */
  transition: background-color 0.3s ease; /* Transition for hover */
}
.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: transparent;
}

.slick-dots li.slick-active button {
  background-color: rgba(216, 1, 58, 1); /* Active dot color */
  border-radius: 25px;
  width: 25px; /* Active dot width */
  height: 12px; /* Active dot height */
}

.slick-dots li button:hover {
  background-color: rgba(200, 200, 200, 1); /* Optional hover effect */
}

.custom-arrow {
  font-size: 24px; /* Adjust arrow size */
  color: rgba(216, 1, 58, 1); /* Arrow color */
  border: 1px solid rgba(216, 1, 58, 1);
  background-color: transparent;
  border-radius: 25px; /* Rounded background */
  width: 20px; /* Width of the arrow button */
  height: 20px; /* Height of the arrow button */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute; /* Positioning for arrows */
  transition: background 0.3s ease;
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for perfect vertical alignment */
  z-index: 1; /* Ensure arrows are above other elements */
}

@media (min-width: 600px) {
  .custom-arrow {
    width: 40px; /* Width of the arrow button */
    height: 40px;
    border-radius: 40px;
  }
}

.custom-arrow:hover {
  background: rgba(216, 1, 58, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1); /* Background color on hover */
}

.next {
  right: 10px; /* Position the next arrow */
}

.prev {
  left: 10px; /* Position the previous arrow */
}
